import React, { useContext, useEffect, useState } from "react"
// Updated import for Shopify Storefront API client
import { createStorefrontApiClient } from "@shopify/storefront-api-client"

import Cookies from "js-cookie"

const SHOPIFY_CART_STORAGE_KEY = "shopify_cart_id"

// Replace shopify-buy with the new Storefront API client
const client = createStorefrontApiClient({
  storeDomain: "checkout.kattbjoern.de",
  apiVersion: "2025-01",
  publicAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN
})

// storeDomain: process.env.GATSBY_SHOPIFY_STORE,
const initialStoreState = {
  discounts: {},
  shopifyClient: client,
  isAdding: false,
  cartIsOpen: false,
  cart: {
    lines: []
  }
}

export const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null
})

const createNewCart = async store => {
  const cartCreateMutation = /* GraphQL */ `
    mutation cartCreate {
      cartCreate {
        cart {
          id
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
          lines(first: 100) {
            edges {
              node {
                id
                quantity
                discountAllocations {
                  discountedAmount {
                    amount
                    currencyCode
                  }
                  discountApplication {
                    value {
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                      ... on PricingPercentageValue {
                        percentage
                      }
                    }
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    product {
                      id
                      title
                      handle
                    }
                    price {
                      amount
                      currencyCode
                    }
                    image {
                      url
                      altText
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
          }

          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }
  `

  const { data } = await store.shopifyClient.request(cartCreateMutation)
  return data.cartCreate.cart
}

const fetchCart = async (store, id) => {
  const cartQuery = /* GraphQL */ `
    query cartQuery($cartId: ID!) {
      cart(id: $cartId) {
        id
        createdAt
        updatedAt
        lines(first: 100) {
          edges {
            node {
              id
              quantity
              discountAllocations {
                discountedAmount {
                  amount
                  currencyCode
                }
                discountApplication {
                  value {
                    ... on MoneyV2 {
                      amount
                      currencyCode
                    }
                    ... on PricingPercentageValue {
                      percentage
                    }
                  }
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  product {
                    id
                    title
                    handle
                  }
                  price {
                    amount
                    currencyCode
                  }
                  image {
                    url
                    altText
                    width
                    height
                  }
                }
              }
            }
          }
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
        }
        checkoutUrl
      }
    }
  `

  const { data } = await store.shopifyClient.request(cartQuery, {
    variables: {
      cartId: id
    }
  })

  return data.cart
}

export const useDiscountsFromState = () => {
  const {
    store: { discounts }
  } = useContext(StoreContext)
  let finalDiscounts = discounts
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) {
    return finalDiscounts
  }

  const keys = Object.keys(discounts)
  if (keys.length === 0) {
    finalDiscounts = JSON.parse(localStorage.getItem("discounts"))
  }

  return finalDiscounts
}

const setCartInState = (cart, setStore) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CART_STORAGE_KEY, cart.id)
  }

  // Format lines to be consistent with our app structure
  const formattedLines = cart.lines.edges.map(edge => edge.node)

  setStore(prevState => {
    return { ...prevState, cart: { ...cart, lines: formattedLines } }
  })
}

const removeCartInState = (cart, setStore) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    localStorage.removeItem(SHOPIFY_CART_STORAGE_KEY)
  }

  setStore(prevState => {
    return { ...prevState, cart }
  })
}

function useCheckout() {
  const {
    store: { cart }
  } = useContext(StoreContext)

  if (!cart.checkoutUrl) {
    return
  }

  const checkoutUrl = new URL(cart.checkoutUrl)

  // Get existing search parameters from the checkout URL
  const existingParams = new URLSearchParams(checkoutUrl.search)

  // Get parameters from the current page URL
  const currentParams = new URLSearchParams(window.location.search)

  // Define parameters from cookies
  const cookieParams = {
    gclid: Cookies.get("gclid"),
    utm_source: Cookies.get("utm_source"),
    utm_medium: Cookies.get("utm_medium"),
    utm_campaign: Cookies.get("utm_campaign"),
    utm_term: Cookies.get("utm_term"),
    utm_content: Cookies.get("utm_content")
  }

  // Function to merge parameters into existing ones
  function mergeParams(sourceParams, targetParams) {
    for (const [key, value] of sourceParams.entries()) {
      if (value && !targetParams.has(key)) {
        targetParams.set(key, value)
      }
    }
  }

  // Merge current URL parameters and cookie parameters into existing parameters
  mergeParams(currentParams, existingParams)
  mergeParams(new URLSearchParams(cookieParams), existingParams)

  // Update the search parameters of the checkout URL
  checkoutUrl.search = existingParams.toString()

  // The fully constructed URL with all parameters
  const fullCheckoutURL = checkoutUrl.toString()

  return () => {
    window.open(fullCheckoutURL, "_self")
  }
}

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState)
  const [initStore, setInitStore] = useState(false)
  const [lineItems, setLineItems] = useState([])

  useEffect(() => {
    if (initStore === false) {
      const initializeCart = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== "undefined"
        const existingCartId = isBrowser
          ? localStorage.getItem(SHOPIFY_CART_STORAGE_KEY)
          : null

        if (existingCartId) {
          try {
            const cart = await fetchCart(store, existingCartId)

            // Check if cart exists and is not abandoned
            // We can verify cart validity by checking if it has an updatedAt timestamp
            // and if it's recent enough (e.g., within the last 2 weeks)
            if (!cart || !cart.updatedAt) {
              throw new Error("Cart not found or expired")
            }

            // Validate that cart items are still available
            if (cart.lines.edges.some(edge => !edge.node.merchandise)) {
              throw new Error(
                "Invalid line item in cart. This merchandise was probably deleted from Shopify"
              )
            }

            const formattedLines = cart.lines.edges.map(edge => edge.node)
            setLineItems(formattedLines)
            setCartInState(cart, setStore)
            return
          } catch (e) {
            console.error("Failed to load cart:", e)
            localStorage.removeItem(SHOPIFY_CART_STORAGE_KEY)
          }
        }

        const newCart = await createNewCart(store)
        setCartInState(newCart, setStore)
      }
      initializeCart()
      setInitStore(true)
    }
  }, [store, setStore, initStore])

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
        lineItems,
        setLineItems
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

function useCartCount() {
  const { lineItems } = useContext(StoreContext)

  let count = 0
  if (lineItems) {
    count = lineItems.reduce(
      (runningTotal, item) => item.quantity + runningTotal,
      0
    )
  }

  return count
}

function useAddItemToCart(cartShouldBeOpened = true) {
  const {
    store: { cart, shopifyClient },
    setStore,
    setLineItems
  } = useContext(StoreContext)

  async function addItemToCart(variantId, quantity) {
    if (variantId === "" || !quantity) {
      console.error("Both a size and quantity are required.")
      return
    }

    setStore(prevState => {
      return { ...prevState, isAdding: true }
    })

    const cartLinesAddMutation = /* GraphQL */ `
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            id
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
            lines(first: 100) {
              edges {
                node {
                  id
                  quantity
                  discountAllocations {
                    discountedAmount {
                      amount
                      currencyCode
                    }
                    discountApplication {
                      value {
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                        ... on PricingPercentageValue {
                          percentage
                        }
                      }
                    }
                  }
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      product {
                        id
                        title
                        handle
                      }
                      price {
                        amount
                        currencyCode
                      }
                      image {
                        url
                        altText
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
            }
            checkoutUrl
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    try {
      const { data } = await shopifyClient.request(cartLinesAddMutation, {
        variables: {
          cartId: cart.id,
          lines: [
            {
              merchandiseId: variantId,
              quantity
            }
          ]
        }
      })

      const updatedCart = data.cartLinesAdd.cart
      const formattedLines = updatedCart.lines.edges.map(edge => edge.node)

      setLineItems(formattedLines)
      setStore(prevState => {
        return {
          ...prevState,
          cart: { ...updatedCart, lines: formattedLines },
          cartIsOpen: cartShouldBeOpened,
          isAdding: false
        }
      })
    } catch (error) {
      console.error("Error adding item to cart:", error)
      setStore(prevState => {
        return { ...prevState, isAdding: false }
      })
    }
  }
  return addItemToCart
}

function useRemoveItemFromCart() {
  const {
    store: { cart, shopifyClient },
    setStore,
    setLineItems
  } = useContext(StoreContext)

  async function removeItemFromCart(lineId) {
    const cartLinesRemoveMutation = /* GraphQL */ `
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
            lines(first: 100) {
              edges {
                node {
                  id
                  quantity
                  discountAllocations {
                    discountedAmount {
                      amount
                      currencyCode
                    }
                    discountApplication {
                      value {
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                        ... on PricingPercentageValue {
                          percentage
                        }
                      }
                    }
                  }
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      product {
                        id
                        title
                        handle
                      }
                      price {
                        amount
                        currencyCode
                      }
                      image {
                        url
                        altText
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
            }
            checkoutUrl
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    try {
      const { data } = await shopifyClient.request(cartLinesRemoveMutation, {
        variables: {
          cartId: cart.id,
          lineIds: [lineId]
        }
      })

      const updatedCart = data.cartLinesRemove.cart
      const formattedLines = updatedCart.lines.edges.map(edge => edge.node)

      setLineItems(formattedLines)
      setStore(prevState => {
        return { ...prevState, cart: { ...updatedCart, lines: formattedLines } }
      })
    } catch (error) {
      console.error("Error removing item from cart:", error)
    }
  }

  return removeItemFromCart
}

function useClearAllFromCart() {
  const {
    store,
    store: { cart },
    setStore
  } = useContext(StoreContext)
  return async () => {
    removeCartInState(cart, setStore)
    const newCart = await createNewCart(store)
    setCartInState(newCart, setStore)
  }
}

function useToggleCart() {
  const {
    store: { cartIsOpen },
    setStore
  } = useContext(StoreContext)

  function toggleCart() {
    setStore(prevState => {
      return { ...prevState, cartIsOpen: !cartIsOpen }
    })
  }

  return toggleCart
}

function useStore() {
  const { store } = useContext(StoreContext)
  return store
}

function useCartItems() {
  const { lineItems } = useContext(StoreContext)

  return lineItems
}

function useCartTotals() {
  const {
    store: { cart }
  } = useContext(StoreContext)
  const { lineItems } = useContext(StoreContext)

  // 1. Build up real subtotal before discounts (unit price × qty)
  let subtotalBeforeDiscounts = 0
  let totalSavingsResult = 0

  lineItems.forEach(item => {
    const unitPrice = parseFloat(item.merchandise.price.amount || 0)
    const quantity = item.quantity || 1
    const lineTotal = unitPrice * quantity
    subtotalBeforeDiscounts += lineTotal

    if (item.discountAllocations && item.discountAllocations.length > 0) {
      item.discountAllocations.forEach(discount => {
        const discountAmount = parseFloat(
          discount.discountedAmount?.amount || 0
        )
        totalSavingsResult += discountAmount
      })
    }
  })

  // 2. Calculate total (after discounts) manually, to match checkout
  const total = (subtotalBeforeDiscounts - totalSavingsResult).toFixed(2)

  return {
    total,
    totalBeforeDiscounts: subtotalBeforeDiscounts.toFixed(2),
    totalSavings: totalSavingsResult > 0 ? totalSavingsResult.toFixed(2) : "-",
    tax: "-" // not exposed via Storefront API
  }
}

function useUpdateItemsFromCart() {
  const {
    store: { cart, shopifyClient },
    setStore,
    setLineItems
  } = useContext(StoreContext)

  async function updateItemsFromCart(line) {
    // Ensure we're working with an array
    const linesToUpdate = Array.isArray(line) ? line : [line]

    const cartLinesUpdateMutation = /* GraphQL */ `
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
            lines(first: 100) {
              edges {
                node {
                  id
                  quantity
                  discountAllocations {
                    discountedAmount {
                      amount
                      currencyCode
                    }
                    discountApplication {
                      value {
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                        ... on PricingPercentageValue {
                          percentage
                        }
                      }
                    }
                  }
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      product {
                        id
                        title
                        handle
                      }
                      price {
                        amount
                        currencyCode
                      }
                      image {
                        url
                        altText
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            cost {
              totalAmount {
                amount
                currencyCode
              }
              subtotalAmount {
                amount
                currencyCode
              }
            }
            checkoutUrl
          }
          userErrors {
            field
            message
          }
        }
      }
    `

    try {
      const { data } = await shopifyClient.request(cartLinesUpdateMutation, {
        variables: {
          cartId: cart.id,
          lines: linesToUpdate.map(line => ({
            id: line.id,
            quantity: line.quantity
          }))
        }
      })

      // Extract line items from the edge/node structure
      const updatedCart = data.cartLinesUpdate.cart
      const formattedLines = updatedCart.lines.edges.map(edge => edge.node)

      setLineItems(formattedLines)

      // Create a new cart object with the lines array directly instead of edge/node structure
      setStore(prevState => {
        return {
          ...prevState,
          cart: {
            ...updatedCart,
            lines: formattedLines // This matches your existing cart structure
          }
        }
      })
    } catch (error) {
      console.error("Error updating cart items:", error)
    }
  }

  return updateItemsFromCart
}

export {
  client,
  StoreContextProvider,
  useAddItemToCart,
  useStore,
  useCartCount,
  useCartItems,
  useCartTotals,
  useRemoveItemFromCart,
  useUpdateItemsFromCart,
  useCheckout,
  useToggleCart,
  useClearAllFromCart
}
